import { getDeviceId, growthbookInitialiseClient, } from '@treatwell/growthbook-js';
import get from 'lodash.get';
import { development, production } from './growthbook/config';
import { initialiseMParticle } from './mParticle';
import eventsModule from '../event-aggregator';
import $ from 'jquery';
import { mParticleGetInstance } from "@treatwell/ui";
const growthbookDeferred = $.Deferred();
window.wahanda.growthbook = growthbookDeferred;
export function initialiseGrowthbookMParticleLibraries() {
    const channelAttributes = {};
    const userAttributes = {};
    const userIdentities = {};
    const environment = get(window, 'wahanda.pageConfig.environment');
    const countryCode = get(window, 'wahanda.pageConfig.channelCountryCode');
    channelAttributes.country = countryCode;
    const events = eventsModule;
    events.on(events.ACCOUNT_INFORMATION, async ({ accountData, isAuthenticated }) => {
        if (isAuthenticated) {
            const { accountId, email } = accountData;
            userAttributes.account_id = String(accountId);
            userAttributes.is_authenticated = true;
            userIdentities.customerid = String(accountId);
            userIdentities.email = email;
        }
        else {
            userAttributes.is_authenticated = false;
        }
        // Ensure that mparticle and growthbook both use the same
        // deviceid for tracking
        const deviceId = await getDeviceId();
        const growthbookConfig = environment === 'PROD' ? production : development;
        growthbookConfig.apiHost = `${location.origin}/api/v2/experiments`;
        growthbookConfig.trackingCallback = (experiment, result) => {
            mParticleGetInstance().then((mParticle) => {
                if (!mParticle) {
                    return;
                }
                mParticle.logEvent("$experiment_started", mParticle.EventType.Other, {
                    platform: "web",
                    is_authenticated: userAttributes.is_authenticated,
                    "Experiment name": experiment.key,
                    "Variant name": result.key,
                    country: countryCode
                });
            });
        };
        const growthbook = await growthbookInitialiseClient(growthbookConfig, deviceId, {
            growthbookAttributes: {
                channel: channelAttributes,
                user: userAttributes,
            },
        });
        initialiseMParticle(deviceId, userIdentities);
        growthbookDeferred.resolve(growthbook);
    });
}
