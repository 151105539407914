/*eslint-disable */
define(function(require) {
    var $                   = require('jquery');
    var _                   = require('underscore');
    var config              = require('common/page-config');
    var Popup               = require('site/common/popup');
    var inputFocus          = require('site/common/input-focus');
    var inputValidation     = require('site/common/input-validation');

    var $popup = $('#verify-account-password-popup');
    var $form = $popup.find('form');
    var $password = $form.find('input');
    var $loginFailure = $form.find('.login-failure');
    var popup = new Popup($popup, {
        width: 380
    });
    
    inputFocus.addClassWhenFocused($form);

    function validate(credentials, callback) {
        inputFocus.addClassWhenFocused($form);

        $form.validate(_.extend(inputValidation, {
            blur:    true,
            rules:    {
                'password': 'required'
            },
            messages:    {
                'password': {
                    required: config.cms.verifyPassword.passwordRequired
                }
            },
            submitHandler: _.bind(submit, null, credentials, callback)
        }));
    }
    
    function submit(credentials, callback) {
        credentials.password = $form.find('[name=password]').val();
        
        $.ajax({
            method: 'POST',
            url: '/ajax/site/login',
            data: credentials,
            success: function (response) {
                if (response.result === 'ACCOUNT_AUTHENTICATED' || response.result === 'PROFILE_AUTHENTICATED') {
                    $popup.off('dialogclose');
                    popup.close();
                    
                    callback('success'); 
                } else {
                    showLoginError(true);
                    $password.focus();
                }
            }
        });
    }
    
    function showLoginError(show) {
        $loginFailure.css('visibility', show ? 'visible' : 'hidden');
    }
    
    return function(credentials, callback) {
        validate(credentials, callback);

        $popup.on('dialogclose', function() {
            $popup.off('dialogclose');
            $password.off('keydown');
            callback('failure'); 
        });

        popup.open();
        $password.on('keydown', _.bind(showLoginError, null, false));
        inputFocus.focusFirstEmptyInput($form);
    };
});
