/* eslint no-new: 0 */
import "babel-polyfill";
import addActionContainerListeners from "common/util/action-container";
import { trackABTastyExperiments } from "./abtasty-track-experiments";

// Most (all?) of the dependencies initialise themselves, and expose nothing.
// This is because they are mostly concerned with providing common behaviour on every page, such as
// adding a ga event to all top navigation item clicks.
define(function (require) {
  var $ = require("jquery");
  var siteTypeSwitch = require("./site-type-switch");
  var pageViews = require("./page-views");
  var config = require("common/page-config");
  var storeHideEmailOptIn = require("common/util/store-hide-email-opt-in");
  var StandaloneLoginView = require("site/common/views/login-standalone-page");
  var StandaloneLoginPageRegisterBlock = require("site/common/views/login-standalone-page-register-block");
  const headerNav = require("./header-nav");
  const { initialiseGrowthbookMParticleLibraries } = require("common/analytics/initialise-growthbook-mparticle");
  initialiseGrowthbookMParticleLibraries();
  require("./footer");
  require("./header-venue-search");
  require("./header-login");
  require("./header-user");
  require("./navigation-links-profile-locations");
  require("./navigation-links-analytics");
  require("./page-content-overlay");

  require("./newsletterSubscribePopup").default();

  $.ajaxSetup({
    headers: { "X-Language-Code": config.languageCode }
  });

  headerNav.render();

  if ($("#standalone-login-form").length) {
    new StandaloneLoginView({
      el: $("#standalone-login-form")
    });
    if ($("#standalone-login-form").length) {
      new StandaloneLoginPageRegisterBlock({
        el: $("#register")
      });
    }
  }

  // initialize localStorage polifill
  var localStoragePolifill = require("common/localStorage-polifill");

  localStoragePolifill.initialise();

  var MarketingPopup = require("./marketing-popup");
  var shouldShowOtherPopups = true;

  // add site type switch listeners
  siteTypeSwitch.addListeners();

  pageViews.increment();
  addActionContainerListeners(".action-container");

  if (shouldShowOtherPopups) {
    new MarketingPopup({
      el: ".marketing-email-popup-dialog"
    });
  }

  storeHideEmailOptIn();

  if (typeof window.criteoRetargettingCallback === "function") {
    window.criteoRetargettingCallback();
  }

  // TODO: Remove this, as the script tag will be added by the analytics
  // Add script tag to the body to load the client attribute cookies
  var s = document.createElement("script");

  s.src = "/nmp/asset/tw-track.js?v=4";
  s.async = true;
  document.head.appendChild(s);

  trackABTastyExperiments();
});
