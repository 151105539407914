/*eslint-disable */
import React from "react";
import { Header } from "@treatwell/front-end-2.0";
import ReactDOM from "react-dom";
import { generateLoginUrl } from './headerNavUtils/login-url';

define(function (require) {
  const signOut = require('site/common/user/sign-out');
  const config = require("common/page-config");

  function handleLoginClick() {
    const protectedActionUrl = generateLoginUrl(
      window.location.hostname,
      window.location.pathname
    );
    window.location.href = protectedActionUrl;
  }

  function handleLogoutClick() {
    signOut(function () {
      window.location.href = "/";
    });
  }

  function loginIsNotSupported(channelFeatures) {
    return !channelFeatures.includes("MARKETPLACE_ACCOUNT_LOGIN") &&
      !channelFeatures.includes("CONNECT_ACCOUNT_LOGIN");
  }

  function getChannelData(config) {
    let countryNormalisedName = null;

    if (config.locationFilters) {
      const locationFilter = config.locationFilters.LOCATION;

      if (locationFilter && locationFilter.clearFilterLink) {
        countryNormalisedName = locationFilter.clearFilterLink.value.v2;
      }
    }

    return {
      languageCode: config.languageCode,
      country: {
        normalisedName: countryNormalisedName,
        countryCode: config.channelCountryCode,
      },
      availableLanguages: config.availableLanguages,
      firstMediaDomain: config.mediaDomain,
    };
  }

  function getHeaderLinks(config, connectUri) {
    const headerLinks = config.headerLinks;

    // do not show login link if not supported by channel
    if (loginIsNotSupported(config.uiChannelFeatures)) {
      headerLinks.login = null;
    }

    // Connect Uri is returned from extranet API call
    // see site/common/header-user.js
    headerLinks.connect.uri = connectUri;

    return headerLinks
  }

  function getMobileHeaderLinks(config) {
    if (loginIsNotSupported(config.uiChannelFeatures)) {
      config.mobileHeaderLinks.login = null;
    }
    return config.mobileHeaderLinks;
  }

  function render(
    isAuthenticated = null,
    isConnect = null,
    connectUri = "",
  ) {
    if (!config.showHeaderNav) {
      return;
    }

    const channelData = getChannelData(config);
    const headerLinks = getHeaderLinks(config, connectUri);
    const mobileHeaderLinks = getMobileHeaderLinks(config);
    const isDefaultLanguageSelected = config.onDefaultLanguage;
    ReactDOM.render((
      <Header
        navigation={config.headerNavigation}
        channel={channelData}
        isDefaultLanguageSelected={isDefaultLanguageSelected}
        alternateLocaleUris={config.alternateLocaleUris || []}
        headerLinks={headerLinks}
        mobileHeaderLinks={mobileHeaderLinks}
        isAuthenticated={isAuthenticated}
        isConnectAccount={isConnect}
        onLoginLinkClick={handleLoginClick}
        onLogoutLinkClick={handleLogoutClick}
      />
    ), document.querySelector(".navigation-responsive"));
  }

  return { render };
});
