const $ = require("jquery");
const _ = require("underscore");
const tagManager = require("common/analytics/google-tagmanager");

const baseHref = $("base").attr("href");

function proceed (evt, action) {
  const targetNode = $(action).parents(".action-container");
  let uri = action.getAttribute("data-href");

  if (action.tagName === "A" && action.getAttribute("href")) {
    uri = action.getAttribute("href");
  }

  // If base tag is present (for example for LMN pages), use it to prefix paths.
  if (baseHref && uri && uri.indexOf("/") === 0) {
    uri = baseHref + uri.substr(1);
  }

  let target = action.getAttribute("target");

  if (target) {
    target = target.replace(/-|\//g, "");
  } else if (evt.ctrlKey || evt.metaKey) {
    target = "_blank";
  }

  if (uri) {
    if (target) {
      evt.preventDefault();
      window.open(uri, target);
    } else if (evt.which === 2) {
      window.open(uri);
    } else {
      window.location = uri;
    }
  }

  const form = targetNode.find("form");

  if (form.length > 0) {
    $(form).trigger("submit");
  }
}

export default function (selector) {
  $(selector).on({
    click (evt) {
      const containerNode = $(this);
      const targetNode = $(evt.target);
      const action = _.first(containerNode.find(".action-uri"));

      if (targetNode.data("reactroot") === "") {
        return;
      }

      if (!action) {
        return;
      }

      evt.preventDefault();

      const trackEventData = tagManager.getTrackEventData(targetNode);

      if (!trackEventData) {
        proceed(evt, action);
        return;
      }

      tagManager.trackEvent(trackEventData, function () {
        proceed(evt, action);
      });
    },
    mouseover () {
      $(this).addClass("action-container-hover");
    },
    mouseleave () {
      $(this).removeClass("action-container-hover");
    }
  });
}
