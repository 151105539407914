/*eslint-disable */
define(function (require) {
    var $ = require('jquery');
    var _ = require('underscore');
    var keyCodes = require('common/key-codes');
    var loginForm = require('site/common/user/login-form');
    var signupChoice = require('site/common/user/signup-choice');
    var config = require('common/page-config');
    var ga = require('common/analytics/google-analytics');
    var gt = require("common/analytics/google-tagmanager");
    var loginEvents = require("site/common/analytics/login-events");

    const trackEventLogInHeader = loginEvents.trackEventLogInHeader();
    var OPENLOGIN_CLASS = 'open-login';
    var LOGGINGIN_CLASS = 'logging-in';
    var LOGGEDIN_CLASS = 'logged-in';
    var FORMERRORS_CLASS = 'errors';
    var OPENLOGIN_BUTTON_CLASS = 'button-on';
    var INPUTERROR_CLASS = 'error';

    // How long showing and hiding login block takes.
    var ANIMATION_PERIOD_MS = 300;

    var $body = $('body');
    var $content = $('.content');
    var $loginBlock = $('#header-login');
    var $closeLoginBlock = $loginBlock.find('.close');
    var $userNav = $('#user-nav');
    var $headerLoginButton = $userNav.find('.a-login');
    var $signupButton = $loginBlock.find('.sign-up .signup');
    var $fbLoginButton = $loginBlock.find('.facebook-button');

    var $message = $loginBlock.find('.message');

    var $form = $loginBlock.find('form');
    var $email = $form.find('.login-email');
    var $password = $form.find('.password');
    var $persistentLogin = $form.find('.persistent-login');
    var $forgotPassword = $form.find('.forgot-password');
    var $treatwellLoginButton = $form.find('.a-login');
    var $inputWrappers = $form.find('.txt-input');

    var $errorMessage = $loginBlock.find('.error-msg .title');
    var errorMessages = $errorMessage.data();

    var $headerLoginButtonInside = $headerLoginButton.find('.button-inside');
    var headerLoginButtonText = $headerLoginButtonInside.text();
    var headerLoginButtonOpenText = $headerLoginButtonInside.data('loginOpenText');

    var animationProperties;

    setAnimationProperties();
    initialiseShowHideEventListeners();
    initialiseSignupListener();
    initialiseForm();

    trackEventLoginForm();

    function initialiseForm() {
        // if no login block don't initialize the form
        if ($loginBlock.length === 0) {
            return;
        }

        var validationOptions = {
            showErrors: function (errorMap, errorList) {
                // Show/hide input field error markers.
                $inputWrappers.removeClass(INPUTERROR_CLASS);
                _.each(errorList, function (error) {
                    $(error.element).parent().addClass(INPUTERROR_CLASS);
                });

                // Show error message (if errors).
                switch (errorList.length) {
                    case 0:
                        $loginBlock.removeClass(FORMERRORS_CLASS);
                        break;
                    case 1:
                        $errorMessage.text(errorList[0].message);
                        $loginBlock.addClass(FORMERRORS_CLASS);
                        break;
                    default:
                        $errorMessage.text(errorMessages.emailAndPasswordRequired);
                        $loginBlock.addClass(FORMERRORS_CLASS);
                }
            },
            invalidHandler: function () {
                trackEventLogInHeader.failure();
            }
        };

        loginForm.initialise($form, errorMessages, validationOptions, function (notification, message) {
            switch (notification) {
                case 'start':
                    removeThenAddClass(FORMERRORS_CLASS, LOGGINGIN_CLASS)
                    break;
                case 'success-profile':
                case 'success-account':
                    removeThenAddClass(LOGGINGIN_CLASS, LOGGEDIN_CLASS);
                    hideLoginBlock();
                    break;
                case 'failure':
                    $errorMessage.text(errorMessages.loginFailure);
                    removeThenAddClass(LOGGINGIN_CLASS, FORMERRORS_CLASS);
                    break;
                case 'failure-channel':
                    $errorMessage.text(errorMessages.loginFailureChannel);
                    removeThenAddClass(LOGGINGIN_CLASS, FORMERRORS_CLASS);
                    ga.trackEvent('site-account', 'channel-mismatch', null);
                    break;
                case 'no_email_permission':
                    $errorMessage.text(errorMessages.loginFailureNoFacebookEmail);
                    removeThenAddClass(LOGGINGIN_CLASS, FORMERRORS_CLASS);
                    break;
                case 'nop':
                    $loginBlock.removeClass(LOGGINGIN_CLASS);
                    break;
                case 'error':
                    $message.text(errorMessages.loginError);
                    removeThenAddClass(LOGGINGIN_CLASS, FORMERRORS_CLASS);
                    break;
                case 'login-error':
                    $errorMessage.text(errorMessages[message]);
                    removeThenAddClass(LOGGINGIN_CLASS, FORMERRORS_CLASS);
                    break;
                case 'cancel':
                    removeThenAddClass(LOGGINGIN_CLASS);
                    hideLoginBlock();
                    break;
            }
        });
    }

    function removeThenAddClass(classToRemove, classToAdd) {
        if (classToRemove) {
            $loginBlock.removeClass(classToRemove);
        }
        if (classToAdd) {
            $loginBlock.addClass(classToAdd);
        }
    }

    function initialiseShowHideEventListeners() {
        // onClick of Login HeaderLink (in FE-2.0) a callback
        // function defined in site/common/header-nav.js is called.
        // this callback fires the headerLoginClick event.
        document.addEventListener('headerLoginClick', function (e) {
            if ($body.hasClass(OPENLOGIN_CLASS)) {
                hideLoginBlock();
            } else {
                showLoginBlock();
            }
        }, false);

        $closeLoginBlock.on('click', hideLoginBlock);

        $(document).keyup(function (event) {
            if (event.keyCode === keyCodes.ESCAPE && $body.hasClass(OPENLOGIN_CLASS)) {
                hideLoginBlock();
            }
        });
    }

    function setAnimationProperties() {
        animationProperties = {
            loginBlockHeight: {},
            contentTop: {}
        };

        animationProperties.loginBlockHeight.loginNotVisible = 0;
        animationProperties.contentTop.loginNotVisible = $content.css('top');

        $body.addClass(OPENLOGIN_CLASS);

        animationProperties.loginBlockHeight.loginVisible = $loginBlock.height();
        animationProperties.contentTop.loginVisible = $content.css('top');

        $body.removeClass(OPENLOGIN_CLASS);
    }

    function showLoginBlock() {
        $headerLoginButton.addClass(OPENLOGIN_BUTTON_CLASS);
        $body.addClass(OPENLOGIN_CLASS);
        animateLoginBlock('loginNotVisible', 'loginVisible', ANIMATION_PERIOD_MS);

        $headerLoginButtonInside.text(headerLoginButtonOpenText);
    }

    function hideLoginBlock() {
        if (!$body.hasClass(OPENLOGIN_CLASS)) {
            return;
        }

        $headerLoginButtonInside.text(headerLoginButtonText);

        animateLoginBlock('loginVisible', 'loginNotVisible', ANIMATION_PERIOD_MS, function () {
            $loginBlock.removeClass(LOGGEDIN_CLASS);
            $headerLoginButton.removeClass(OPENLOGIN_BUTTON_CLASS);
            $body.removeClass(OPENLOGIN_CLASS);
        });
    }

    function animateLoginBlock(startState, endState, duration, callback) {
        $loginBlock.height(animationProperties.loginBlockHeight[startState]);
        $content.css('top', animationProperties.contentTop[startState]);

        $loginBlock.animate({ height: animationProperties.loginBlockHeight[endState] }, duration);
        $content.animate({ top: animationProperties.contentTop[endState] }, duration, callback);
    }

    function trackEventLoginForm() {

        $email.on('focus', function () {
            trackEventLogInHeader.email();
        });
        $password.on('focus', function () {
            trackEventLogInHeader.password();
        });
        $persistentLogin.on('click', function () {
            trackEventLogInHeader.persistentToggled(!!this.checked);
        });
        $forgotPassword.on('click', function () {
            trackEventLogInHeader.forgotPassword();
        });
        $closeLoginBlock.on('click', function () {
            trackEventLogInHeader.closeBlock();
        });
    }

    function hideLoginBlockNoAnimation() {
        if (!$body.hasClass(OPENLOGIN_CLASS)) {
            return;
        }

        $headerLoginButtonInside.text(headerLoginButtonText);

        animateLoginBlock('loginVisible', 'loginNotVisible', 0, function () {
            $loginBlock.removeClass(LOGGEDIN_CLASS);
            $headerLoginButton.removeClass(OPENLOGIN_BUTTON_CLASS);
            $body.removeClass(OPENLOGIN_CLASS);
        });
    }

    function initialiseSignupListener() {
        if (!_.contains(config.uiChannelFeatures, 'FACEBOOK_LOGIN')) {
            return;
        }

        $signupButton.click(function (event) {
            event.preventDefault();
            trackEventLogInHeader.signUp();
            signupChoice(function (action) {
                if (action === 'facebook-login') {
                    $fbLoginButton.click();
                }
            });
        });
    }

    function noUserNav() {
        $userNav.remove();
    }

    return {
        closeWithoutAnimation: hideLoginBlockNoAnimation,
        close: hideLoginBlock,
        noUserNav: noUserNav
    };
});
