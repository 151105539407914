// eslint-disable-next-line max-len
// https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-tracker/advanced-usage/methods-which-can-be-used-from-within-a-callback/

/**
 * Transform the array returned from getDomainUserInfo in to
 * a more useable structure.
 *
 * @param {string[]} info an array returned from getDomainUserInfo.
 * @returns {Object} a DomainUserInfo populated with data from the array.
 */
export function extractDomainUserInfo (info) {
  // The array indexes used must match the documentation for the
  // getDomainUserInfo function.
  let i = 0;

  return {
    firstSession: info[i++],
    userId: info[i++],
    cookieTimestamp: info[i++],
    visitCount: info[i++],
    currentVisitTimestamp: info[i++],
    lastVisitTimestamp: info[i++],
    sessionId: info[i++]
  };
}

export function snowplowReady () {
  return new Promise((resolve) =>
    window.snowplowSafe(function () {
      resolve(this);
    })
  );
}
