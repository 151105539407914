define(function (require) {
  const $ = require("jquery");
  const Backbone = require("backbone");
  const ga = require("common/analytics/google-tagmanager");

  const StandaloneLoginPageRegisterBlock = Backbone.View.extend({
    ui: {
      signUpButton: $("#signup-button", this.$el)
    },

    events: {
      "click #signup-button": "trackEventSignUpButton"
    },

    initialize () {},

    trackEventSignUpButton () {
      ga.trackEvent({ category: "log_in_page", property: "sign_up_button", action: "click" });
    }
  });

  return StandaloneLoginPageRegisterBlock;
});
