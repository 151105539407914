/*eslint-disable */
define(function(require) {
    var $       = require('jquery');
    var Popup   = require('site/common/popup');
    var URI     = require('URIjs/URI');

    var $popup = $('#facebook-account-login-choice-popup');
    var $facebookLoginButton = $popup.find('.facebook-button');
    var $createPasswordButton = $popup.find('.create-password');

    var callback;

    var popup = new Popup($popup, {
        width: 380
    });

    $facebookLoginButton.click(function() {
        $popup.off('dialogclose');
        popup.close();
        callback('facebook-login');
    });

    $createPasswordButton.click(function() {
        $popup.off('dialogclose');
        popup.close();
        callback();
    });

    return function(requestPasswordToken, cb) {
        callback = cb;

        var uri = new URI($createPasswordButton.attr('href'));
        uri.query({token: requestPasswordToken});
        $createPasswordButton.attr('href', uri.toString());

        $popup.on('dialogclose', function() {
            $popup.off('dialogclose');
            callback();
        });

        popup.open();
    };
});
