import React from "react";
import ReactDOM from "react-dom";
import PopupContainer from "./PopupContainer";


let isInitialized = false;
let popupContainer = null;

function initialize () {
  if (isInitialized) {
    return;
  }

  isInitialized = true;

  const reactContainer = document.createElement("div");

  document.body.appendChild(reactContainer);


  let cmsData = null;

  try {
    cmsData = window.wahanda.pageConfig.cms.common["newsletter-subscribe-popup"];
  } catch (error) {
    return;
  }

  const showPostcode = window.wahanda.pageConfig.channel !== "WHN_IE";
  const privacyUri = window.wahanda.pageConfig.privacyLinkUrl;

  const popupContainerElement = (
    <PopupContainer
      cmsData={cmsData}
      showPostcode={showPostcode}
      privacyUri={privacyUri}
      ref={(ref) => { popupContainer = ref; }}
    />
  );

  ReactDOM.render(popupContainerElement, reactContainer);
}

function openNewsletterSubscribePopup () {
  initialize();

  if (!popupContainer) {
    return;
  }

  popupContainer.showPopup();
}


export default function () {
  window.openNewsletterSubscribePopup = openNewsletterSubscribePopup;
}
