import PropTypes from "prop-types";
import React from "react";
import NewsletterSubscribe from "react-components/NewsletterSubscribe";
import NewsletterSubscribePopup from "react-components/NewsletterSubscribePopup";
import jQuery from "jquery";
import Cookies from "js-cookie";
import dataLayer from "common/analytics/dataLayer";

export default class PopupContainer extends React.PureComponent {

  static propTypes = {
    cmsData: PropTypes.object.isRequired,
    showPostcode: PropTypes.bool.isRequired,
    privacyUri: PropTypes.string.isRequired
  };

  static getCsrfCookie () {
    // Do not decode URI encoded cookies.
    // Rainbow AuthTokenService expects an exact match between the CSRF header
    // and the ident cookie. That's probably perfectly reasonable.
    const cookies = Cookies.withConverter((value) => value).get();

    for (let cookieName in cookies) {
      if (cookieName.substr(-4) === "ITKT") {
        return cookies[cookieName];
      }
    }

    return null;
  }

  state = {
    activeView: NewsletterSubscribe.EnumView.signup,
    isVisible: true
  }

  constructor (props, context) {
    super(props, context);
  }

  showPopup () {
    this.setState({ isVisible: true });
    this.trackEvent("view_modal");
  }

  onSubmit = (data) => {
    const headers = {};
    const csrfCookie = PopupContainer.getCsrfCookie();

    if (csrfCookie) {
      headers["X-CSRF-TOKEN"] = csrfCookie;
    }

    jQuery.ajax({
      url: "/api/v1/newsletter-subscription",
      type: "POST",
      contentType: "application/json",
      headers,
      data: JSON.stringify(data)
    })
    .done(() => {
      this.setState({ activeView: NewsletterSubscribe.EnumView.success });
      this.trackEvent("subscription_submitted", "subscription_created");
    }).fail(() => {
      this.setState({ activeView: NewsletterSubscribe.EnumView.failure });
      this.trackEvent("subscription_submitted", "subscription_failed");
    });
  }

  onClose = () => {
    this.setState({
      isVisible: false,
      activeView: NewsletterSubscribe.EnumView.signup
    });
    this.trackEvent("close_modal");
  }

  trackEvent (action, label) {
    dataLayer.track({
      event: "trackEvent",
      eventData: {
        category: "newsletter_modal",
        action,
        label,
        property: undefined,
        value: undefined
      }
    });
  }

  i18n = (key) => {
    let PREFIX_LENGTH = "common.newsletter-subscribe-popup.".length;
    const [key1, key2] = key.substr(PREFIX_LENGTH).split(".");

    return this.props.cmsData[key1][key2];
  }

  render () {
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <NewsletterSubscribePopup
        activeView={this.state.activeView}
        textColor={this.props.cmsData.colors.text}
        controlsForegroundColor={this.props.cmsData.colors["controls-foreground-color"]}
        controlsBackgroundColor={this.props.cmsData.colors["controls-background-color"]}
        showPostcodeInput={this.props.showPostcode}
        privacyUri={this.props.privacyUri}
        onSubmit={this.onSubmit}
        i18n={this.i18n}
        onClose={this.onClose}
        />
    );
  }
}
