/* global Modernizr */
define(function (require) {
  const $ = require("jquery");
  const Backbone = require("backbone");
  const pageConfig = require("common/page-config");
  const VerifyEmailRemote = require("common/form-validation-rules/verify-email");
  const BetterLoginPopupView = require("site/common/views/betterLoginPopupView");
  const loginEvents = require("site/common/analytics/login-events");
  const loginPageEvents = loginEvents.trackEventLogInPage();
  const StandaloneLoginView = Backbone.View.extend({
    ui: {
      email: $("#emaiId", this.$el),
      password: $("#passwordId", this.$el),
      persist: $("#persistent-login", this.$el),
      target: $("#targetPageId", this.$el)
    },

    events: {
      "focus #emaiId": "trackEventEmail",
      "focus #passwordId": "trackEventPassword",
      "click #persistent-login": "trackEventPersistentLogin",
      "click #reset-password": "trackEventResetPassword"
    },

    initialize () {
      if (!Modernizr.postmessage) {
        $(":input", this.$el).attr("disabled", true);
        $("button", this.$el).hide();
        alert(pageConfig.cms["standalone-login-page"]["browser-version-error-text"]);
      }

      this.addFormValidation();
    },

    addFormValidation () {
      this.$el.validate({
        blur: true,
        rules: {
          email: {
            required: true,
            email: true,
            remote: VerifyEmailRemote(this.ui.email, pageConfig.validationMessages["email-address"])
          },
          password: {
            required: true
          }
        },
        focusInvalid: false,
        submitHandler: this.submit.bind(this),
        invalidHandler: this.invalid.bind(this),
        errorClass: "error",
        errorElement: "p"
      });
    },

    invalid () {
      loginPageEvents.failure();
    },

    trackEventEmail () {
      loginPageEvents.email();
    },
    trackEventPassword () {
      loginPageEvents.password();
    },
    trackEventPersistentLogin () {
      loginPageEvents.persistentToggled(!!this.ui.persist.prop("checked"));
    },
    trackEventResetPassword () {
      loginPageEvents.forgotPassword();
    },

    successHandler (data) {
      if (data.result === "PROFILE_AUTHENTICATED" || data.result === "ACCOUNT_AUTHENTICATED") {
        loginPageEvents.success();
        window.location = this.ui.target.val();
      } else if (data.result === "CHANNEL_MISMATCH") {
        $("#login-message-authentication-error-channel").show();
        loginPageEvents.failure();
      } else if (data.result === "LOCKED") {
        $("#login-message-locked").show();
        loginPageEvents.failure();
      } else if (data.result === "RATE_LIMITED") {
        $("#login-message-rate-limited").show();
        loginPageEvents.failure();
      } else if (data.result === "REQUIRES_RESET" || data.result === "LOCKED") {
        var BetterLoginPopup = new BetterLoginPopupView({
          message: data.result.toLowerCase().replace("_", "-"),
          cleanupFields: [this.ui.password]
        });

        BetterLoginPopup.render();
        loginPageEvents.failure();
      } else {
        $("#login-message-authentication-error").show();
        loginPageEvents.failure();
      }
    },

    submit () {
      const formData = {
        email: this.ui.email.val(),
        password: this.ui.password.val(),
        persistentLogin: this.ui.persist.prop("checked")
      };

      $.ajax({
        async: true,
        url: "/ajax/site/login/",
        type: "POST",
        data: formData,
        success: this.successHandler.bind(this)
      });

      return false;
    }
  });

  return StandaloneLoginView;
});
