/*eslint-disable */
define(["jquery", "underscore", 'modernizr'], function($, _) {

    var eventType = Modernizr.touch ? "touchstart" : "click";
    var callbackArray = [];
    var shownCallbackArray = [];
    window.$overlay = $("<div class='page-content-overlay b-nav-overlay'></div>");
    window.$overlay.css({
        "z-index": parseInt($(".overlay").css("z-index")) + 10,
        display: "none"
    });
    $("body").append($overlay);

    function showOverlay(overlayId) {
        $('.b-nav-overlay').show();
        $(".overlay").fadeIn(200);
        $("body").addClass('faded');
        addClickListener();

        _.each(shownCallbackArray, function(callbackFunction) {
            callbackFunction(overlayId);
        });
    };

    function hideOverlay() {
        $('.b-nav-overlay').hide();
        $(".overlay").fadeOut(200);
        $("body").removeClass('faded');
        removeClickListener();
    };

    function onOverlayClick(callback) {
        callbackArray.push(callback);
    };

    function onOverlayShown(callback) {
        shownCallbackArray.push(callback);
    };

    /**
     * Adds a onClick listener on document.
     *
     * If a click is triggered on an element that has, or is a child of an element having, the class "page-content-overlay-persist"
     *    the overlay will not be closed and the attached callbacks will not be fired.
     */
    function addClickListener() {
        removeClickListener();
        $(document).on(eventType+'.page-content-overlay', function(event) {
            var $clickTarget = $(event.target);
            // The click originated on an element that does not prevent closing of the overlay
            if ($clickTarget.closest('.page-content-overlay-persist').length === 0) {
                hideOverlay();
                _.each(callbackArray, function(callbackFunction) {
                    callbackFunction();
                });
            }
        });
    }

    function removeClickListener() {
        $(document).off(eventType+'click.page-content-overlay');
    }

    return {
        show: showOverlay,
        hide: hideOverlay,
        onOverlayClick: onOverlayClick,
        onOverlayShown: onOverlayShown
    }
})
