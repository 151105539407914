/*eslint-disable */
define(["common/analytics/google-analytics"], function(ga) {
    $("#site-header .nav li a").on("click", function(event) {
        var $anchor = $(this);
        var $parentAnchor = $anchor.parents("td").find("div > a");
        var uri = $anchor.attr("href");

        // NB sync so that event is safely logged before returning from here, 
        // and allowing the navigation to a new page.
        ga.trackEvent("site", "top-navigation", uri, true);

        // The alternative would be to prevent default click behaviour, and the go to the new
        // page by setting window.location when the request has completed.
        // However that interferes with middle-click behaviour.
    });
});
