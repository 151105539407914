define(function (require) {
  const $ = require("jquery");
  const cookieName = "page_views";

  function getCount () {
    return parseInt($.cookie(cookieName) || "0", 10);
  }

  function setCount (count) {
    $.cookie(cookieName, count, {
      expires: 365,
      path: "/"
    });
  }

  function increment () {
    setCount(getCount() + 1);
  }

  return {
    increment: increment,
    getCount: getCount
  };
});
