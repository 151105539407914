define(function (require) {
  const Backbone = require("backbone");
  const $ = require("jquery");
  const Popup = require("site/common/popup");
  const pageConfig = require("common/page-config");

  const BetterLoginPopup = Backbone.View.extend({
    popupOptions: {
      dialogClass: "wahanda-popup reset-password-dialog",
      notTitlebar: true,
      closeOnEscape: false,
      ignoreOverlayClick: true,
      hideCloseButton: true,
      width: 500
    },

    ui: {},

    initialize (options) {
      this.cms = pageConfig.cms["reset-password-popup"] || "";
      this.message = options.message;
      this.cleanupFields = options.cleanupFields;
    },

    addEvents () {
      this.ui.cancel.on("click", () => this.popup.close());
      this.ui.confirm.on("click", this.openResetFlow.bind(this));
    },

    openResetFlow () {
      // open new tab
      window
        .open("/request-password", "_blank")
        .focus();

      // close popup
      this.popup.close();
      // reset value for the cleanup fields
      if (this.cleanupFields && this.cleanupFields.length) {
        this.cleanupFields.forEach((field) => field.val(""));
      }
    },

    renderPopupContent () {
      const content = this.renderContent();
      const actions = this.renderActions();

      return $("<form/>").addClass("reset-password-dialog-form").append(content, actions);
    },

    renderContent () {
      this.ui.content = $("<div/>")
        .addClass("form-content")
        .html(this.cms[this.message]);
      return this.ui.content;
    },

    renderActions () {
      const cancelBtn = this.renderCancel();
      const confBtn = this.renderConfirmation();

      return $("<div/>")
        .addClass("actions")
        .append(cancelBtn, confBtn);
    },

    renderCancel () {
      this.ui.cancel = $("<div/>").addClass("not-now link").text(this.cms.actions.cancel);
      return this.ui.cancel;
    },

    renderConfirmation () {
      this.ui.confirm = $("<div/>").addClass("button main-button").text(this.cms.actions.confirm);
      return this.ui.confirm;
    },

    render () {
      const popupContent = this.renderPopupContent();

      this.popup = new Popup(popupContent, this.popupOptions);
      this.popup.open();

      this.addEvents();
    }
  });

  return BetterLoginPopup;
});
