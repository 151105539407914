/*eslint-disable */
define(function (require) {
    var $ = require('jquery');
    var _ = require('underscore');
    require('jquery-cookie');
    var accountInformation = require('site/common/user/account-information');
    var extranetAccount = require('site/common/user/extranet-account-information');
    var headerNav = require("./header-nav");

    var events = require('common/event-aggregator');

    var connectUri;
    var authStatus = false;

    function setupHeader() {
        var isAuthenticated = $.cookie("twAuthenticated") === "true";
        var isConnect = $.cookie("twExtranet") === "true";

        // rerender header nav with updated auth status
        headerNav.render(isAuthenticated, isConnect, connectUri);
    }

    function userInfoListeners() {
        events.listenTo(events, events.PROFILE_LOGIN, function (responseData) {
            // the data passed through the event promise is not the same structure as that passed
            // in through the accountInformation().get() callback.
            var data = function () {
                if (responseData.result === "PROFILE_AUTHENTICATED" || responseData.result === "ACCOUNT_AUTHENTICATED") {
                    return _.extend(responseData, { loginStatus: responseData.result });
                } else {
                    return responseData;
                }
            };

            onAccountInformation(data());
        });
    }

    function setCookiesToFalse() {
        $.cookie("twAuthenticated", "false", { path: "/" });
        $.cookie("twExtranet", "false", { path: "/" });
    }

    function getAuthStatus(accountData) {
        var authenticatedStatus = ['PROFILE_AUTHENTICATED', 'ACCOUNT_AUTHENTICATED'];

        return _.contains(authenticatedStatus, accountData.loginStatus) && accountData.loginStatus !== "NOT_RECOGNISED";
    }

    function onAccountInformation(accountData, newAccountInformation) {
        var isAuthenticated = getAuthStatus(accountData);
        var userSignedOut = (isAuthenticated !== authStatus) && !isAuthenticated;
        authStatus = isAuthenticated;
        events.trigger(events.ACCOUNT_INFORMATION, {accountData, isAuthenticated})
        if (isAuthenticated) {
            $.cookie("twAuthenticated", "true", { path: "/" });

            extranetAccount(function (extranet) {
                if (extranet) {
                    connectUri = extranet.url;
                }

                var isConnect = !!extranet;
                $.cookie("twExtranet", isConnect.toString(), { path: "/" });
            }).always(function () {
                setupHeader();
            });
        } else {
            setCookiesToFalse();
        }

        if (newAccountInformation) {
            newAccountInformation.done(onAccountInformation);

            // do not update Header if user has just signed out,
            // as we are just about to redirect.
            if (!userSignedOut) {
                setupHeader()
            }
        }
    }

    function initialize() {
        userInfoListeners();
        setupHeader();
        accountInformation.get().done(onAccountInformation);
    }

    initialize();
});
