/**
 * Verify plausability of an email address.
 *
 * Unlike sibling modules, that typically add jqueryvalidation rules, this one is used as
 * a jqueryvalidation remote function.
 * See http://jqueryvalidation.org/remote-method for more details.
 *
 * jqueryvalidation rules must operate synchronously. This means that if implemented as
 * a rule, the XHR has to be synchronous. If the request take more that few hundred ms
 * things get ugly very quickly. The page's whole ui is frozen until the request completes.
 *
 * A single function is exposed, that should be used as a remote function.
 * Example:
 *
 *      'contact-email': {
 *          required: true,
 *          remote: verifyEmailRemote($clientEmail, $clientEmail.data('validation-email'))
 *      }
 */

define(function (require) {
  var log = require("common/log");

  /**
   * @param {Object}  $field    a jQuery object representing the field to be validated.
   * @param {String}  message   an optional message, used if validation fails.
   * @returns {String}  return result stringifyed
   */
  return function ($field, message) {
    return {
      url: "/api/v2/validate-email-address",
      method: "POST",
      contentType: "application/json; charset=utf-8",
      beforeSend (_xhr, settings) {
        settings.data = JSON.stringify({
          email: $field.val()
        });
      },
      dataFilter (data) {
        var result;
        var parsedData = JSON.parse(data);
        if (parsedData === true) {
          result = true;
        } else {
          log.client(log.INFO, "email validation for " + $field.val() + " failed.");
          result = message;
        }

        return JSON.stringify(result);
      }
    };
  };
});
