const loginUrls = {
    'www.treatwell.at': 'geschuetzte-aktion',
    'www.treatwell.be': 'beveiligde-actie',
    'www.treatwell.ch': 'geschuetzte-aktion',
    'www.treatwell.co.uk': 'protected-action',
    'www.treatwell.de': 'geschuetzte-aktion',
    'www.treatwell.es': 'accion-protegida',
    'www.treatwell.fr': 'action-protegee',
    'www.treatwell.ie': 'protected-action',
    'www.treatwell.it': 'azione-protetta',
    'www.treatwell.lt': 'saugus-veiksmas',
    'www.treatwell.nl': 'beveiligde-actie',
};
const matchDomainWithUrl = (hostname) => {
    const matched = Object.keys(loginUrls).find((domain) => {
        const domainRegex = new RegExp(domain);
        return domainRegex.test(hostname);
    });
    // handle fallback if no match
    if (!matched) {
        return loginUrls['www.treatwell.co.uk'];
    }
    return loginUrls[matched];
};
export const generateLoginUrl = (hostname, route = '/') => {
    const loginUrlPath = matchDomainWithUrl(hostname);
    return `/${loginUrlPath}/?route=${route}`;
};
