// /*eslint-disable */
define(function (require) {
  const Backbone = require("backbone");
  const $ = require("jquery");
  const _ = require("underscore");
  const Mustache = require("mustache");
  const pageConfig = require("common/page-config");
  const cms = pageConfig.cms;
  const accountInformation = require("site/common/user/account-information");
  const persistence = require("site/page/home/search-persistence");
  const events = require("common/event-aggregator");

  const NavigationLinksProfileUpdate = Backbone.View.extend({
    linkContainer: {},
    urlPostalLocationTemplates: "",
    locationUriPatterns: {},
    account: null,
    anchors: [],

    initialize (options) {
      this.linkContainer = options.linkContainer;

      // TODO: ADD EXTERNAL LOCATION SUPPORT
      if (cms.urlPostalLocationTemplates) {
        this.urlPostalLocationTemplates = cms.urlPostalLocationTemplates.common
          .replace("{{postalPrefix}}", cms.urlPostalLocationTemplates.postal)
          .replace("{{locationPrefix}}", cms.urlPostalLocationTemplates.location)
          .replace("{{extPlacePrefix}}", cms.urlPostalLocationTemplates.externalplace)
          .replace("{{geocodePrefix}}", cms.urlPostalLocationTemplates.geolocation);
      }

      this.locationUriPatterns = this.linkContainer.data("profileLocationUriPatterns");
      this.fixupUriPatterns();
      this.getAccountInformation();
    },

    addEvents () {
      events.on(events.SEARCH_LOCATION_CHANGE, this.rewriteLinks.bind(this));
    },

    // For each pattern, generate a RexExp and a list of eligible location types.
    fixupUriPatterns () {
      if (!this.locationUriPatterns) {
        // Not all pages (for example checkout) have top nav and location uri patterns.
        return;
      }

      $.each(this.locationUriPatterns, function (index, locationUriPattern) {
        locationUriPattern.pattern = new RegExp(locationUriPattern.pattern);
        locationUriPattern.locations = locationUriPattern.locations.split(",");
      });
    },

    rewriteLinks () {
      _.each(this.anchors, _.bind(this.rewriteLink, this));
    },

    rewriteLink (link) {
      const anchor = $(link);

      if (!anchor.data("uriTemplate")) {
        return;
      }

      _.each(this.locationUriPatterns, (pattern) => this.updateLocation(pattern, anchor));
    },

    getAccountInformation () {
      accountInformation.get().done((account) => {
        this.account = account;

        // Not all pages (for example checkout) have top nav and location uri patterns.
        if (!this.locationUriPatterns) {
          return;
        }

        // merge both nav item's links and subnav item's links into a single array
        this.anchors = $.merge(this.linkContainer.find("a"), $(".nav-unit.no-subnav a"));

        // Update the links and set up event listener
        this.rewriteLinks();
        this.addEvents();
      });
    },

    getLocationType (location) {
      if (!location) {
        return {};
      }

      return {
        isLocation: !location.coords && location.id,
        isPostal: location.postalReferenceId,
        postalRef: location.postalReferenceId,
        isExternal: location.externalId,
        isExtPlace: location.placeId,
        isGeolocation: location.coords
      };
    },

    updateLocation (pattern, anchor) {
      let location;
      let uriTemplate = anchor.data("uriTemplate");

      if (pattern.pattern.test(uriTemplate)) {
        let persistedLocation = persistence.get(pattern.persistedLocation);

        if (persistedLocation) {
          // check type of location
          if (persistedLocation.coords) {
            let coords = persistedLocation.coords;

            persistedLocation.normalisedName = `${coords.latitude},${coords.longitude}`;
          }

          location = persistedLocation;
        } else {
          location = this.account.selectLocation(pattern.locations);
        }

        if (!location) {
          return;
        }

        const templateData = _.extend({}, {
          location: location.normalisedName,
          placeId: location.placeId
        }, this.getLocationType(location));
        const locationOrPostalRef = Mustache.render(this.urlPostalLocationTemplates, templateData);
        const newUri = uriTemplate
          .replace("{{locationOrPostalRef}}", locationOrPostalRef)
          .replace("{{location}}", location.normalisedName);

        anchor.attr("href", newUri);
      }
    }
  });

  return new NavigationLinksProfileUpdate({
    linkContainer: $(".uri-location-replace")
  });
});
