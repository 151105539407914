define(function (require) {
  const $ = require("jquery");

  return function () {
    const $channelSelector = $(".channel-selector");
    const $otherChannels = $channelSelector.find(".other-channels");
    const $currentChannel = $channelSelector.find(".current-channel");

    function setOtherChannelsWidth () {
      // Make all channels blocks the same width as the current channel.
      //
      // Just setting the width for $currentChannel sometimes results in
      // a 1px difference. It seems that this is down to a fraction of
      // a pixel in Chrome's renderer. Hence set the width of both children.
      //
      // (Cannot easily do this in css.)
      const $footerBottomNode = $("#footer .bottom");

      const oldVisibility = $footerBottomNode.is(":visible");

      $footerBottomNode.show();
      $otherChannels.show();
      $channelSelector.children().css("width", `${$otherChannels.outerWidth()}px`);
      $otherChannels.hide();
      $footerBottomNode.toggle(oldVisibility);
    }

    function closeChannelSelector (event) {
      // Close, when click is anywhere outside of channel selector.
      if (
        $currentChannel.find(".channel-toggle")[0] !== event.target &&
        event.target !== $currentChannel[0] && event.target !== $otherChannels[0]
      ) {
        /* eslint no-use-before-define: 0 */
        toggleSelector();
      }
    }

    function toggleSelector (open = false) {
      $channelSelector.toggleClass("active", open);
      $otherChannels.toggle(open);

      if (open) {
        $(document).on("click", closeChannelSelector);
      } else {
        $(document).off("click", closeChannelSelector);
      }
    }

    function toggleChannelSelector () {
      // Toggle the state of the channel selector.
      toggleSelector(!$channelSelector.hasClass("active"));
    }

    setOtherChannelsWidth();
    $currentChannel.on("click", toggleChannelSelector);
  };
});
