/*eslint-disable */
define(function(require) {
    var $                   = require('jquery');
    var Popup               = require('site/common/popup');

    var $popup = $('#signup-choice-popup');
    var $facebookLoginButton = $popup.find('.facebook-button');
    var popup = new Popup($popup, {
        width: 380
    });
    
    return function(callback) {
        $facebookLoginButton.on('click', function() {
            $facebookLoginButton.off('click');
            $popup.off('dialogclose');
            popup.close();
            callback('facebook-login'); 
        });

        $popup.on('dialogclose', function() {
            $popup.off('dialogclose');
        });

        popup.open();
    };
});
