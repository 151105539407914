import { extractDomainUserInfo, snowplowReady } from "./snowplow";
import { trackEventAsync } from "common/analytics/google-tagmanager";

const MAX_ATTEMPTS = 10;
const RETRY_DELAY = 100; // milliseconds;

export async function trackABTastyExperiments () {
  if (!window.ABTasty || !window.ABTasty.getTestsOnPage) {
    return;
  }

  const snowplow = await snowplowReady();
  const sessionId = extractDomainUserInfo(snowplow.cfn.getDomainUserInfo())
    .sessionId;

  const tests = window.ABTasty.getTestsOnPage();
  for (const testId in tests) {
    trackTest(testId, sessionId, 1);
  }
}

function trackTest (testId, sessionId, attempt) {
  if (attempt > MAX_ATTEMPTS) {
    // Too many attempts. Give up.
    console.warn( // eslint-disable-line no-console
      `Failed to find data to track test ${testId} after ${MAX_ATTEMPTS} attempts`
    );
    return;
  }

  const tests = window.ABTasty.getTestsOnPage();
  const test = tests[testId];

  // The data returned from ABTasty.getTestsOnPage does not appear to
  // contain the variation details initially. So if it"s not present
  // try again in a little while.
  if (test.variationID === undefined) {
    // Not available yet. Try again shortly.
    setTimeout(() => trackTest(testId, attempt + 1), RETRY_DELAY);
    return;
  }

  if (isAlreadyTracked(sessionId, testId, test.variationID)) {
    return;
  }

  trackEventAsync({
    category: "experimentation",
    property: testId,
    action: "ab_tasty_test_loaded",
    label: test.variationName,
    value: test.variationID
  });
}

/**
 * Determine whether the triplet of sessionId/testId/variantId
 * has already been tracked with a structured event.
 *
 * If the permutation has not previously been tracked, mark it
 * as tracked.
 *
 * @param {string} sessionId the snowplow session for the user.
 * @param {string} testId the test"s identifier.
 * @param {number} variationId the test variant"s identifier.
 *
 * @returns {boolean} true if the permutation has previously been tracked.
 */
function isAlreadyTracked (sessionId, testId, variationId) {
  const key = "abtastyTracking";
  const instanceKey = `${sessionId}-${testId}-${variationId}`;

  const value = JSON.parse(localStorage.getItem(key) || "{}");

  if (value[instanceKey]) {
    // Already tracked this permutation.
    return true;
  }

  // Persist that this permutation is tracked.
  value[instanceKey] = true;
  localStorage.setItem(key, JSON.stringify(value));

  return false;
}
