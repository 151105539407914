/*eslint-disable */
define(function(require) {
  var $ = require('jquery');

  var $exploreVenuesTrigger = $('.explore-venues-trigger');
  var $exploreVenuesMenu = $('.explore-venues-menu');


  function toggleMenu (evt) {
    var inExploreVenues = $(evt.target).parents('.explore-venues-menu').length > 0;

    if (!inExploreVenues) {
      closeMenu();
    }
  }

  function openMenu () {
    $exploreVenuesTrigger.addClass('on');
    // position the menu at the middle of the trigger
    var newPosition = $exploreVenuesTrigger.position().left + ($exploreVenuesTrigger.outerWidth() / 2) - ($exploreVenuesMenu.width() / 2);
    $exploreVenuesMenu.css('left', newPosition).show();

    // This is here because of the event propagation.
    // Because this link is now part of the navigation,
    // the navigation was triggering the click event, thus the menu was closing
    // With this, the attach of the events only occurs after the event has propagated
    setTimeout(function () {
      $(document).on('click closeUserNav', toggleMenu);
      $(window).on('resize', closeMenu);
    }, 0);
  }

  function closeMenu () {
    $exploreVenuesTrigger.removeClass('on');
    $exploreVenuesMenu.hide();

    $(document).off('click closeUserNav', toggleMenu);
    $(window).off('resize', closeMenu);
  }

  $exploreVenuesTrigger.on('click touch', function (e) {
    e.preventDefault();

    var trigger = $(this);

    if (trigger.hasClass('on')) {
      return closeMenu();
    }

    openMenu();
  });
});
