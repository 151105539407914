/*eslint-disable */
define(["underscore", "modernizr"], function(_) {
    var ITEM_NAME = "homepage-search";

    function get(name) {
        if (!Modernizr.localstorage) {
            return;
        }

        var item = JSON.parse(localStorage.getItem(ITEM_NAME) || "{}");

        if (name) {
            return item[name];
        } else {
            return item;
        }
    }

    function set(name, value) {
        if (!Modernizr.localstorage) {
            return;
        }

        var item = get();

        item[name] = value;
        localStorage.setItem(ITEM_NAME, JSON.stringify(item));
    }

    return {
        get        : get,
        set        : set,
        TYPE_LOCAL : 'serviceSearchLocation',
        TYPE_ESCAPE: 'spaSearchLocation'
    };
});
