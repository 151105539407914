/*eslint-disable */
define(function (require) {
    'use strict';

    var config = require('common/page-config');

    var Backbone = require('backbone'),
        _ = require('underscore'),
        $ = require('jquery'),
        AccountInformation = require('site/common/user/account-information'),

        config = require('common/page-config'),
        log = require('common/log'),
        URI = require('URIjs/URI'),
        inputFocus = require('site/common/input-focus');

    var MarketingPopup = Backbone.View.extend({
        templateId: 'marketing-popup-template',
        formId: 'newsletter-marketing-signup-form',

        cookies: {
            emailDelay: 'marketing-email-delay',
            popupDelay: 'emailPopupDelay',
            popupPageId: 'emailPopupPageId',
            noMore: 'noMore'
        },

        noMoreCookie: false,

        initialize: function (options) {
            var uri = new URI(window.location.href),
                fragment = uri.fragment(),
                reg = new RegExp(/show-popup=(\d+)&?/);

            // on mobile, config.marketingPopup does not exist
            config.marketingPopup = config.marketingPopup || {};
            this.settings = {
                forceShow: config.marketingPopup.forceShow,
                delayMilliseconds: parseInt(config.marketingPopup.delayMilliseconds, 10),
                delayCountThreshold: parseInt(config.marketingPopup.delayCountThreshold, 10),
                pageGroupId: parseInt(config.marketingPopup.pageGroupId, 10)
            };
            this.options = options || {};

            this.templateId = this.options.templateId ? this.options.templateId : this.templateId;
            this.formId = this.options.formId ? this.options.formId : this.formId;
            this.width = this.options.width ? this.options.width : 695;
            this.settings.forceShow = this.options.forceShow ? this.options.forceShow : this.settings.forceShow;

            // add fragment to settings
            this.settings.fragment = fragment ? fragment : null;

            if (this.settings.fragment) {
                this.settings.forceShow = this.settings.fragment.match(reg) ? this.settings.fragment.match(reg)[1] : null;
            }

            // if channel feature is disabled return
            if (!_.contains(config.uiChannelFeatures, 'NEWSLETTER_SUBSCRIPTION')) {
                return;
            }

            // get user account
            AccountInformation.get().done(_.bind(function (account) {
                // if display name then delay cookie
                if (account.displayName) {
                    this.setCookie(this.cookies.emailDelay, 1000, {path: '/'});
                    return;
                }

                this.render();
            }, this));
        },

        getCookie: function (name) {
            return !name ? $.cookie() : $.cookie(name);
        },

        setCookie: function (name, delay, options) {
            if (!name) {
                return;
            }

            $.cookie(name, delay, options);
        },

        logMessage: function (message) {
            window.wlog(message);
        },

        render: function () {
            var popupSettings,
                action = 'show',
                template = $('#' + this.templateId).html();

            // if no template content return
            if (!template || template === '') {
                return;
            }

            if (!this.canShowPopup()) {
                return;
            }

            // if there's already a popup remove it
            if (this.popup) {
                this.popup.remove();
            }

            // set the popup settings
            popupSettings = this.getPopupSettings();

            // if popup is not to show, return
            if (!popupSettings.show) {
                return;
            }

            // set the popup into the view
            this.popup = $('<div/>').addClass('marketingDialog');

            // if there is a fragment then set action to load
            if (this.settings.fragment) {
                action = 'load';
            }

            // load or show the popup
            this[action + 'Popup']();
        },

        postRender: function () {
            // set the page delay cookie to make sure it is not shown
            // again for a few months
            this.setCookie(this.cookies.emailDelay, 1000, {
                expires: 90,
                path: '/'}
            );

            $('.ui-widget-overlay').last().css({
                'background-color': 'black',
                'background-image': 'none',
                'opacity': '0.7',
                'position': 'fixed'
            });

            if (this.popup.find('.no-more-cookie').text() === 'true') {
                this.noMoreCookie = true;
            }

            // initialize the dialog's form
            this.initializeForm();
        },

        canShowPopup: function () {
            if (config.isMobile) {
                return false;
            }

            var newsletterTrackerCookieValue,
                noMoreCookie = this.getCookie('noMore' + this.settings.forceShow);

            // Even if we've been asked to show a given popup - don't if we've been told not to.
            if (noMoreCookie && parseInt(noMoreCookie, 10) === 1) {
                return false;
            }

            // check to see if we have succesfully registered before
            if (typeof getNewsletterTrackerCookieValue === 'function') {
                newsletterTrackerCookieValue = window.getNewsletterTrackerCookieValue();

                if (newsletterTrackerCookieValue === 'suppress' || newsletterTrackerCookieValue === 'done') {
                    return false;
                }
            }

            // check to see if this is one of the blocked pages
            if (typeof window.blockMarketingEmailPopup !== 'undefined' && window.blockMarketingEmailPopup === true) {
                window.wlog('This page has blocked the marketing popup');
                return false;
            }

            return true;
        },

        getPopupSettings: function () {
            var delaySeconds, delayCount,
                cookieEmailPopupPageId = parseInt(this.getCookie(this.cookies.popupPageId), 10),
                newVisitorOverlayName = $('#content').data('new-visitor-overlay-name'),
                settings = {
                    show: false,
                    forceShow: false,
                    delay: 0,
                    pageId: null
                };

            if (this.settings.forceShow) {
                log.client(log.INFO, 'Force show the marketing popup');
                this.logMessage('Force show the marketing popup');
                // update settings
                _.extend(settings, {
                    show: true,
                    forceShow: this.settings.forceShow
                });
            } else {

                if (cookieEmailPopupPageId) {
                    // set the delay seconds
                    delaySeconds = this.settings.delayMilliseconds;

                    if (this.getCookie(this.cookies.popupDelay)) {
                        delaySeconds = this.getCookie(this.cookies.popupDelay);
                    }

                    // log message
                    this.logMessage('show marketing email popup ' + cookieEmailPopupPageId + ' in ' + delaySeconds + ' seconds.');

                    // set cookies
                    this.setCookie(this.cookies.emailDelay, 1000, {path:'/'});
                    this.setCookie(this.cookies.popupPageId, null, {path: '/'});
                    this.setCookie(this.cookies.popupDelay, null, {path: '/'});

                    // update settings page id and delay
                    _.extend(settings, {
                        pageId: cookieEmailPopupPageId,
                        delay: delaySeconds,
                        show: true
                    });
                } else {
                    if (this.getCookie(this.cookies.emailDelay) > 0) {
                        delayCount = parseInt(this.getCookie(this.cookies.emailDelay));
                        ++delayCount;

                        if (delayCount === this.settings.delayCountThreshold) {
                            // update settings
                            _.extend(settings, {
                                show: true,
                                delay: this.settings.delayMilliseconds
                            });
                        } else if (delayCount < this.settings.delayCountThreshold) {
                            this.logMessage('marketing email delay count at ' + delayCount + ', below threshold of ' + this.settings.delayCountThreshold);
                            this.setCookie(this.cookies.emailDelay, delayCount, {path: '/'});
                        }
                    } else {
                        if (newVisitorOverlayName) {
                            wahanda.showNewVisitorOverlay(newVisitorOverlayName);
                            this.logMessage('marketing email delay count set to prevent it being shown after the NVO');
                        } else {
                            this.logMessage('marketing email delay count initialised (at 1)');
                        }

                        this.setCookie(this.cookies.emailDelay, newVisitorOverlayName ? 1000 : 1, {path: '/'});
                    }
                }
            }

            return settings;
        },

        loadPopup: function () {
            var params = {
                    'page-uri': window.escape(window.location),
                    'page-type': 'marketing-email-popup',
                    'page-delay-count': config.marketingPopup.delayCountThreshold,
                    'page-group-id': config.marketingPopup.pageGroupId
                };

            // if there is a force show value, add it to the params
            if (this.settings.forceShow) {
                params['page-id'] = this.settings.forceShow;
            }

            // load page
            $.ajax({
                type: 'GET',
                url: '/ajax/site/template-page/',
                data: params,
                dataType: 'html',
                success: _.bind(function (data) {
                    this.createPopup();

                    // Get template and append the html to the popup contents
                    this.popup.html(data).dialog('open');

                    // run the post render stuff
                    this.postRender();
                    this.noMoreCookie = false;
                }, this),
                error: function (xhr, status, error) {
                    window.wlog('Error loading marketing popup template');
                }
            });
        },

        showPopup: function () {
            var template;

            // create the popup
            this.createPopup();

            // Get template and append the html to the popup contents
            template = $('#' + this.templateId).html();
            this.popup.html(template).dialog('open');

            // run the post render stuff
            this.postRender();
        },

        createPopup: function () {
            var top;

            return this.popup.dialog({
                autoOpen: false,
                dialogClass: 'chromeless-popup',
                modal: true,
                resizable: false,
                width: this.width,
                open: _.bind(function () {
                    // Sometimes, for reasons not fully understood, the popup is not vertically centerd.
                    // To workaround this, animate (so as to avoid a nasty jump) to a centered position.
                    setTimeout(_.bind(function() {
                        top = ($(window).innerHeight() - $(this.popup).parent().outerHeight()) / 2;

                        $(this.popup).parent().animate({
                            top: top + 'px'
                        }, 300, _.bind(function () {
                            $(window).on('scroll resize', _.bind(function () {
                                this.centerPopup.call(this);
                            }, this));
                        }, this));
                    }, this), 500);
                }, this),
                close: _.bind(function () {
                    // If no more cookie, set the no more cookie
                    if (this.noMoreCookie) {
                        this.setCookie(this.cookies.noMore + this.settings.forceShow, 1, {
                            expires: 10000,
                            path: '/'
                        });
                    }

                    // remove window scroll listener
                    $(window).off('scroll', this.centerPopup);
                }, this)
            });
        },

        centerPopup: function () {
            this.popup.dialog('option', 'position', 'center');
        },

        initializeForm: function () {
            var form = this.popup.find('#' + this.formId),
                typeInput = form.find(':input[name="source-type-code"]');

            // if type input node doesn't exist, create it and add it it the form
            if (typeInput.length === 0) {
                typeInput = $('<input type="hidden" name="source-type-code"/>');
                typeInput.appendTo(this.formId);
            }
            // set type input value
            typeInput.val('EF');

            // blur all form's fields
            this.popup.find(this.formId + ' input').blur();
            this.popup.find(this.formId + ' input.clickclear').hint({
                attr: 'title',
                keepLabel: true
            });

            // add css class when fields are focused
            inputFocus.addClassWhenFocused(form);

            // add form validation rules
            form.validate({
                blur: true,
                rules: {
                    'email': {
                        required: true,
                        email: true
                    },
                    'postcode': {
                        required: !(this.popup.find('.disable-postcode-validation').text() === 'true'),
                        minlength: 2
                    }
                },
                submitHandler: _.bind(function (form) {
                    this.submitForm(form);
                }, this),
                errorClass: 'newsletter-error',
                errorPlacement: function (error, element) {
                    return true;
                }
            });
        },

        submitForm: function (form) {
            var pageId = this.getCookie(this.cookies.popupPageId),
                $popup = this.popup;//,
                //$dialogName = this.popup.hasClass('marketingDialog') ? this.popup : this.popup.find('.marketingDialog');

            $.ajax({
                type: 'post',
                url: '/ajax/site/newsletter-subscription/',
                data: $(form).serialize(),
                dataType: 'html',
                success: _.bind(function (data) {
                    // close the dialog
                    $popup.dialog('close');

                    window.trackNewsletterSignup('EmailPopup');
                    if (this.noMoreCookie) {
                        // Deploy the cookie that will disable this popup for quite some time
                        // as the user has successfully submitted the form
                        this.setCookie(this.cookies.noMore + pageId, 1, {
                            expires: 10000,
                            path: '/'
                        });
                    }

                    // call global wahanda post signup function
                    this.redirect(data);
                }, this)
            });
        },

        redirect: function (data) {
            window.wahanda.signup.postSignupPopup({
                email: $('#newsletter-marketing-signup-email').val(),
                html: data,
                redirect: false,
                redirectOnClose: false
            });
        }
    });

    return MarketingPopup;
});
