define(function (require) {
  var $ = require("jquery");
  var _ = require("underscore");

  var events = require("common/event-aggregator");
  var inputFocus = require("site/common/input-focus");
  var keyCodes = require("common/key-codes");
  var accountInformation = require("site/common/user/account-information");
  var VerifyEmailRemote = require("common/form-validation-rules/verify-email");
  var verifyPassword = require("site/common/user/verify-password");
  var { initialiseFacebook, getFacebookAuthToken } = require("common/facebook-api/facebook-api-login");
  var facebookAccountWithNoPassword = require("site/common/user/facebook-account-login-choice");
  var PageConfig = require("common/page-config");
  var BetterLoginPopupView = require("site/common/views/betterLoginPopupView");
  var loginEvents = require("site/common/analytics/login-events");
  var headerLoginEvents = loginEvents.trackEventLogInHeader();
  var ACCOUNT_REQUIRES_RESET = "REQUIRES_RESET";
  var ACCOUNT_LOCKED = "LOCKED";
  var RATE_LIMITED = "RATE_LIMITED";
  const { mParticleCustomEventLogIn } = require("common/analytics/mParticle");

  // How long to wait following successful login before informing caller.
  // Allows successful login message to be seen.
  var POSTLOGIN_DELAY_MS = 300;

  function initialise ($form, errorMessages, additionalValidationOptions, callback) {
    var $email = $form.find(".login-email");
    var $password = $form.find(".password");
    var $persistentLogin = $form.find(".persistent-login");
    var $loginButton = $form.find(".button.a-login");
    var $fbLoginButton = $form.find(".facebook-button");


    function initialiseFormValidation () {
      var formErrorMessages = errorMessages ? errorMessages : {
        emailRequired: $email.data("email-validation"),
        passwordRequired: $password.data("password-validation")
      };

      var validationOptions = {
        onfocusout: false,
        rules: {
          email: {
            required: true,
            email: true,
            remote: VerifyEmailRemote($email, PageConfig.validationMessages["email-address"])
          },
          password: {
            required: true
          }
        },
        messages: {
          email: {
            required: formErrorMessages.emailRequired
          },
          password: formErrorMessages.passwordRequired
        },
        focusInvalid: false
      };

      _.extend(validationOptions, additionalValidationOptions || {});

      $form.validate(validationOptions);
    }

    function formInputsSubmitForm () {
      $form.find("input").keyup(function (event) {
        if (event.keyCode === keyCodes.ENTER) {
          $loginButton.click();
        }
      });
    }

    function deferredCallback (done, notification) {
      var trackEvent = notification === "failure-channel" ? "none" : "userLoginSuccess";

      setTimeout(function () {
        accountInformation.refresh(false)
          .done(function (account) {
            accountInformation.track(account, trackEvent);
            done(notification);
          })
        ;
      }, POSTLOGIN_DELAY_MS);
    }

    function login (credentials) {
      credentials.persistentLogin = $persistentLogin.prop("checked");

      callback("start");

      // make call to login
      const xhrRequest = $.ajax({
        async: true,
        method: "POST",
        url: "/ajax/site/login",
        data: credentials,
        success (response) {
          /* Deliberate split of header name */
          if (xhrRequest.getResponseHeader("X" + "-Acc" + "ount") === "Limited") {
            response.result = RATE_LIMITED;
          }

          // TODO: AMP-715 - add case for GOOGLE_AND_NO_PASSWORD
          if (response.result === "PROFILE_AUTHENTICATED") {
            deferredCallback(callback, "success-profile");
            headerLoginEvents.success(!!credentials.facebookAccessToken);
            events.trigger(events.PROFILE_LOGIN, response);
          } else if (response.result === "ACCOUNT_AUTHENTICATED") {
            deferredCallback(callback, "success-account");
            if (credentials.facebookAccessToken) {
              mParticleCustomEventLogIn("facebook");
            }
            if (!credentials.facebookAccessToken) {
              mParticleCustomEventLogIn("password");
            }
            headerLoginEvents.success(!!credentials.facebookAccessToken);
            events.trigger(events.PROFILE_LOGIN, response);
          } else if (response.result === "CHANNEL_MISMATCH") {
            deferredCallback(callback, "failure-channel");
            headerLoginEvents.failure(!!credentials.facebookAccessToken);
          } else if (response.result === "REQUIRES_PASSWORD_VERIFICATION") {
            verifyPassword(credentials, function (result) {
              if (result === "success") {
                callback("success-account");
                headerLoginEvents.success(!!credentials.facebookAccessToken);
                accountInformation.refresh();
              } else {
                callback("failure");
                headerLoginEvents.failure(!!credentials.facebookAccessToken);
              }
            });
          } else if (response.result === "FACEBOOK_AND_NO_PASSWORD") {
            facebookAccountWithNoPassword(response.requestPasswordToken, function (result) {
              if (result === "facebook-login") {
                $fbLoginButton.click();
              } else {
                callback("nop");
                headerLoginEvents.failure(true);
              }
            });
          } else if (response.result === "FACEBOOK_EMAIL_DENIED") {
            callback("no_email_permission");
            headerLoginEvents.failure(true);
          } else if (response.result === RATE_LIMITED) {
            callback("login-error", "rateLimited");
            headerLoginEvents.failure(!!credentials.facebookAccessToken);
          } else if (response.result === ACCOUNT_REQUIRES_RESET || response.result === ACCOUNT_LOCKED) {
            callback("cancel", "");
            headerLoginEvents.failure(!!credentials.facebookAccessToken);

            var BetterLoginPopup = new BetterLoginPopupView({
              message: response.result.toLowerCase().replace("_", "-"),
              cleanupFields: [$password]
            });

            BetterLoginPopup.render();
          } else {
            // failure
            callback("failure");
            headerLoginEvents.failure(!!credentials.facebookAccessToken);
          }
        },
        error () {
          callback("error");
          headerLoginEvents.failure(!!credentials.facebookAccessToken);
        }
      });
    }

    function formLoginButtonListener () {
      $loginButton.on("click", function (event) {
        event.preventDefault();

        if ($form.valid()) {
          login({
            email: $email.val(),
            password: $password.val()
          });
        }
      });
    }

    function facebookLoginButtonListener () {
      initialiseFacebook()
        .then(() => {
          $fbLoginButton.removeAttr("disabled");
          $fbLoginButton.on("click", () => {
            getFacebookAuthToken()
              .then((accessToken) => {
                login({ facebookAccessToken: accessToken });
              })
              .catch((reason) => {
                callback(reason || "nop");
                headerLoginEvents.failure(true);
              });
          });
        });
    }

    initialiseFormValidation();
    formInputsSubmitForm();
    inputFocus.addClassWhenFocused($form);
    formLoginButtonListener();
    facebookLoginButtonListener();
  }

  return {
    initialise: initialise
  };
});
