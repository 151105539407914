/*eslint-disable */
define(["jquery"], function ($) {
  return function (callback) {

    return $.ajax({
      url: "/ajax/site/extranet-account",
      success:
        function (data) {
          var extranetAccount;

          if (data.hasOwnProperty("type") && data.type === "LINKED") {
            extranetAccount = data;
          } else if (data.hasOwnProperty("data") && data.data && data.data.hasOwnProperty("type") && data.data.type === "LINKED") {
            extranetAccount = data.data;
          } else {
            extranetAccount = false;
          }

          if (callback) {
            callback(extranetAccount);
          }
        }
    });
  };
});
