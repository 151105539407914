define(function (require) {
  var $ = require("jquery");
  var accountInformation = require("site/common/user/account-information");

  return function (success) {
    $.ajax({
      type: "POST",
      url: "/ajax/site/logout/",
      success () {
        accountInformation.refresh(false).done(success);
      }
    });
  };
});
