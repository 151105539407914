/*eslint-disable */
define(['jquery'], function ($) {
    var _ = require("underscore");
    var PageConfig = require("common/page-config");
    return {
      highlight: function (element) {
        parent(element).addClass('error');
      },
  
      unhighlight: function (element) {
        parent(element).removeClass('error');
      },
  
      errorPlacement: function (error, element) {
        error = error.text();
        var errorContainer = element.parent().find('.error-message');
  
        const isNewRegisterAPIsEnabled = _.contains(
          PageConfig.uiChannelFeatures,
          "USE_NEW_ACCOUNT_API"
        );

        if (isNewRegisterAPIsEnabled && element.attr('id') === 'password') {
          return true;
        }
  
        if (errorContainer.length > 0) {
          errorContainer.find('span').html(error);
        } else {
          const errorWrapper =
            '<div class="error-message"><span>' + error + '</span></div>';
          $(errorWrapper).insertAfter(element);
        }
        return true;
      },
    };
  
    function parent(element) {
      return $(element).parents('.form-row, .wrapper').filter(':first');
    }
  });
  