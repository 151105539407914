define(function (require) {
  const URI = require("URIjs/URI");
  const config = require("common/page-config");

  function shouldHideOptIn () {
    if (!config.isWidget) {
      return false;
    }

    const query = new URI().query(true);

    return query.utm_source === "email" ||
        query.utm_medium === "email";
  }

  return function storeHideEmailOptIn () {
    if (shouldHideOptIn()) {
      window.sessionStorage.setItem("hideEmailOptIn", "true");
    }
  };
});
